<template>
  <v-card>
    <v-card-text>
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
        :show-select="false"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Ürünler"
            icon="mdi-package-variant"
            :add-route="{ name: 'admin.products.create' }"
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            hide-edit
            :search.sync="search.query"
            v-bind="titleBarAttrs"
          ></rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'admin.products.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_available="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.monthly_price_per_house="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.yearly_price_per_house="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.created_at="{ value }">
          <rs-table-cell-date show-time :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.updated_at="{ value }">
          <rs-table-cell-date show-time :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.actions="{ item }">
          <rs-table-button-delete
            @deleted="handleDeleteClick(item)"
            confirmed
            :loading="isLoading"
          />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";

export default {
  mixins: [filtersToURL, hasExport, hasPermissions],
  data() {
    return {
      options: {
        sortBy: ["name"],
      },
      dataTableAttrs: {
        footerProps: {
          disableItemsPerPage: false,
        },
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Adı",
          value: "name",
          searchable: "text",
          width: "150px",
        },
        {
          text: "Fatura Kalemi",
          value: "invoice_description",
          searchable: "text",
          width: "150px",
        },
        {
          text: "Aylık Tutar/BB",
          value: "monthly_price_per_house",
          searchable: "number",
          align: "end",
        },
        {
          text: "Yıllık Tutar/BB",
          value: "yearly_price_per_house",
          searchable: "number",
          align: "end",
        },
        {
          text: "Durum",
          value: "is_available",
          searchable: "isActive",
          align: "center",
        },
        {
          text: "Oluşturma",
          value: "created_at",
          searchable: "date",
          width: "180px",
        },
        {
          text: "Düzenleme",
          value: "updated_at",
          searchable: "date",
          width: "180px",
        },
        {
          text: null,
          value: "actions",
          searchable: false,
          sortable: false,
          align: "right",
        },
      ],
      titleBarAttrs: {
        exportUrl: () => `products`,
        exportParams: this.getParams,
      },
    };
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      return params;
    },
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];

      const params = this.getParams();

      this.$api
        .query("api-super-admin/products", { params })
        .then((response) => {
          this.loadListFromResponse(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleDeleteClick(item) {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      return this.$api
        .delete(`api-super-admin/products/${item.id}`)
        .then(() => {
          this.$toast.success(this.$t("toasts.item_deleted"));
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        })
        .then(() => {
          this.loadList();
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }, 250),
      deep: true,
    },
  },
};
</script>
